import React from "react"
import { Link as GatsbyLink } from "gatsby"
import fontawesome from "@fortawesome/fontawesome"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"
import brands, {
  faTwitter,
  faFacebook,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/fontawesome-free-brands"
import styled from "styled-components"
fontawesome.library.add(brands)

const StyledFooter = styled.footer`
  align-items: center;
  justify-content: center;
  left: 0;
  bottom: 0;
  background-color: #000000;
  color: white;
  text-align: center;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: #1a1a1a 2px solid;
`
const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  padding-right: 1rem;
  padding-left: 1rem;
  @media (max-width: 768px) {
    grid-gap: 3rem;
    section {
      text-align: left;
      grid-gap: 1rem;
    }
  }
`

const Section = styled.section`
  display: grid;
  p {
    text-align: left;
    padding: 5px;
  }
`

const FooterBottom = styled.div``

const Heading = styled.span`
  font-size: 20px;
`

const Link = styled(GatsbyLink)`
  color: white;
  font-size: 20px;
  text-decoration: none;
  :hover {
    color: #f15da6;
  }
`
const Anchor = styled.a`
  color: white;
  text-decoration: none;
  :hover {
    color: #f15da6;
  }
`

const FontAwesomeIcon = styled(Icon)`
  margin: 5px;
`

export default function Footer() {
  return (
    <StyledFooter>
      <Content>
        <Section>
          <Heading>About</Heading>
          <p>
            The only singular brand across the country that every woman turns to
            for confidence, for inspiration and for motivation.
          </p>
          <p>
            Contact:{" "}
            <Anchor href="mailto: you@alpha-woman.com">
              you@alpha-woman.com
            </Anchor>
          </p>
        </Section>
        <Section>
          <Heading>Quick Links</Heading>
          <Link to="/">Home</Link>
          <Link to="/blog">Blog</Link>
          <Link to="/about">About</Link>
        </Section>
        <Section>
          <Heading>Stay In Touch</Heading>
          <Anchor
            target="_blank"
            rel="noopener noreferrer"
            href=" https://www.facebook.com/YouAlphaWoman-2089024278065445/?view_public_for=2089024278065445"
          >
            <FontAwesomeIcon icon={faFacebook} color="white" size="2x" />
          </Anchor>
          <Anchor
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/YouAlphaWoman"
          >
            <FontAwesomeIcon icon={faTwitter} color="white" size="2x" />
          </Anchor>
          <Anchor
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/company/youalphawoman/about/"
          >
            <FontAwesomeIcon icon={faLinkedinIn} color="white" size="2x" />
          </Anchor>
          <Anchor
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/you.alphawoman/?hl=en"
          >
            <FontAwesomeIcon icon={faInstagram} color="white" size="2x" />
          </Anchor>
        </Section>
      </Content>
      <FooterBottom>
        Copyright &copy; {new Date().getFullYear()} alpha-woman.com
      </FooterBottom>
    </StyledFooter>
  )
}
