import PropTypes from "prop-types"
import React from "react"
import { Link as GatsbyLink } from "gatsby"
import styled from "styled-components"
import DrawerToggle from "../components/drawertoggle"

const StyledHeader = styled.header`
  display: grid;
  background: black;
  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  overflow: hidden;
  border-bottom: #1a1a1a 2px solid;
  font-size: 30px;
  z-index: 200;
`
const StlyedNav = styled.nav`
  display: flex;
  height: 100%;
  align-items: center;
`

const NavBarToggle = styled.div`
  @media (min-width: 769px) {
    display: none;
  }
`

const Spacer = styled.div`
  flex: 1;
`

const Link = styled(GatsbyLink)`
  color: white;
  text-decoration: none;
  :hover {
    color: #f15da6;
  }
`

const StyledList = styled.ul`
  display: grid;
  grid-auto-columns: 100px;
  grid-template-rows: auto;
  grid-gap: 5px;
  grid-auto-flow: column;
  margin: 0;
  @media (max-width: 768px) {
    display: none;
  }
`

const ListItem = styled.li`
  display: grid;
  grid-auto-columns: 100px;
  grid-gap: 5px;
  grid-template-rows: auto;
  text-align: center;
`

const Header = props => (
  <StyledHeader>
    <StlyedNav>
      <NavBarToggle>
        <DrawerToggle click={props.drawerClickHandler} />
      </NavBarToggle>
      <Spacer />
      <StyledList>
        <ListItem>
          <Link to="/">Home</Link>
        </ListItem>
        <ListItem>
          <Link to="/blog">Blog</Link>
        </ListItem>
        <ListItem>
          <Link to="/about">About</Link>
        </ListItem>
      </StyledList>
    </StlyedNav>
  </StyledHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
