import React from "react"
import styled from "styled-components"

const BackDropContaier = styled.div`
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 100;
`

export default function BackDrop(props) {
  return <BackDropContaier onClick={props.click} />
}
