import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from "../images/aw_logo_small.svg"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  min-width: 100px;
  flex-direction: column;
  align-items: center;
  margin: auto;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  p {
    margin: 5px;
    font-size: 2rem;
    font-family: Philosopher, sans-serif;
    background: -webkit-linear-gradient(left, #fe070c, #f259a0);
    background-clip: unset;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  img,
  svg {
    width: 80%;
    max-width: 600px;
  }
  @media (max-width: 769px) {
    p {
      font-size: 17px;
    }
  }
`

const TagLineContainer = styled.span`
  height: auto;
  text-align: center;
  @media (max-width: 769px) {
    width: 380px;
  }
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Container>
      <img src={logo} alt="logo of alpha woman" />
      <TagLineContainer>
        <p>
          She is intelligent. She makes moves. She knows her worth. She invests
          in herself. She is confident.
        </p>
        <p>She is aspirational. She is sexy.</p>
        <p>
          She is <span style={{ fontSize: "3rem" }}>YOU.</span>
        </p>
      </TagLineContainer>
    </Container>
  </Layout>
)

export default IndexPage
