import React from "react"
import styled from "styled-components"
import { Link as GatsbyLink } from "gatsby"

const SideNav = styled.nav`
  height: 100%;
  background: black;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 70vw;
  max-width: 400px;
  z-index: 200;
  transform: translateX(${props => (props.show ? 0 : "-100%")});
  transition: transform 0.3s ease-out;
  @media (min-width: 769px) {
    display: none;
  }
`

const StyledList = styled.ul`
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 2rem;
  li {
    margin: 0.5rem 0;
  }
`

const Link = styled(GatsbyLink)`
  color: white;
  text-decoration: none;
  :hover {
    color: #f15da6;
  }
`

export default function SideDrawer(props) {
  return (
    <SideNav show={props.show}>
      <StyledList>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/blog">Blog</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
      </StyledList>
    </SideNav>
  )
}
