/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import Header from "./header"
import SideDrawer from "./sidedrawer"
import Backdrop from "./backdrop"
import Footer from "./footer"
import "../styles/layout.scss"
import styled from "styled-components"

const Content = styled.div`
  height: auto;
`

const Layout = ({ children }) => {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false)

  const drawerToggle = () => {
    setSideDrawerOpen(!sideDrawerOpen)
  }

  const backdropClickHandler = () => {
    setSideDrawerOpen(false)
  }

  let backdrop

  if (sideDrawerOpen) {
    backdrop = <Backdrop click={backdropClickHandler} />
  }

  return (
    <div style={{ background: "black" }}>
      <Content>
        <Header drawerClickHandler={drawerToggle} />
        <SideDrawer show={sideDrawerOpen} />
        {backdrop}
        <main>{children}</main>
      </Content>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
