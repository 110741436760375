import React from "react"

export default function DrawerToggle(props) {
  return (
    <a onClick={props.click}>
      <svg width="30" height="30">
        <path d="M0,5 30,5" stroke="#ffffff" strokeWidth="5" />
        <path d="M0,14 30,14" stroke="#ffffff" strokeWidth="5" />
        <path d="M0,23 30,23" stroke="#ffffff" strokeWidth="5" />
      </svg>
    </a>
  )
}
